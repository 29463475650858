import { useDropzone } from "react-dropzone";
import { BsUpload } from "react-icons/bs";
import React, { useCallback,useMemo } from "react";
import "../assets/css/dropzone.css"

const Dropzone = ({ selectedImage, setSelectedImage }) => {
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "#2f234f",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
    transition: "border .24s ease-in-out",
    marginBottom: "20px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedImage(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: "image/*",
      maxFiles: 1,
    });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const selected_image = selectedImage?.map((file) => (
    <div className="dropzone-container">
      <img src={file.preview} alt="" className="dropzone-image" />
    </div>
  ));
  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className="dragdrop">
          <div className="dragdrop-icon">
            <BsUpload />
          </div>
          <h1>
            Drag & Drop or <span>Choose file</span> to upload
          </h1>
          <h2>Maximum file size 2MB</h2>
        </div>
      </div>
      {selected_image}
    </div>
  );
};


export default Dropzone