import React from "react";
import main from "../assets/images/main-image.jpg";
import undergrad from "../assets/images/undergrad.jpg";
import masters from "../assets/images/masters.jpg";
import training from "../assets/images/training.jpg"
import { IoSettingsOutline } from "react-icons/io5";
import { MdLiveTv } from "react-icons/md";
import { useState, useEffect } from "react";
import { LuContact2 } from "react-icons/lu";
import { HeartIcon } from "../components/heartIcons";



const Home = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <section
        className="relative bg-dark-bg h-[700px] md:h-[700px] flex justify-center pt-40 md:justify-start md:pt-52 pl-1 md:pl-5 lg:px-20"
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 90%, 0 100%)",
        }}
      >
        <div className="absolute bg-[#39FF14] rounded-full h-[300px] w-[300px] -top-20 -left-20 md:h-[500px] md:w-[500px] md:-top-40 md:-left-40"></div>

        <div className="w-11/12 md:w-[700px] relative z-10">
          <div className="flex flex-row">
            <h1 className="text-white font-semibold text-4xl leading-[40px] md:leading-none md:text-6xl relative">
              Ignite your passion and
              <span className="relative">
                <span className="absolute hidden md:block md:-top-4 md:left-4">
                  <HeartIcon/>
                </span>
              </span>
              propel your projects to remarkable success.
            </h1>
          </div>
          <button className="bg-[#39FF14] text-white py-[20px] md:py-[22px] px-5 md:px-9 mt-10 uppercase text-xs md:text-sm font-bold tracking-widest sm:mr-5">
            Explore our solutions
          </button>
          <button className="border-2 border-white text-white py-5 px-6 md:px-7 mt-5 uppercase text-xs md:text-sm font-bold tracking-widest">
            Get a project's estimated cost
          </button>
        </div>
      </section>
      <section className="bg-[#1a1b1b] absolute h-screen w-full px-5 lg:px-20">
        <div className="mt-[-90px] h-[300px] md:h-full w-full z-20 rounded-md">
          <img
            src={main}
            alt=""
            className="h-full w-full object-cover object-top rounded-md"
          />
        </div>
      </section>
      <section className="absolute h-[500px] w-full mt-[250px] md:mt-[600px]">
        <div className="container mx-auto p-4 md:p-1">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="h-[350px] flex flex-col items-center justify-between py-12 px-10 md:px-1 lg:px-10">
              <div className="h-20 w-20 border-2 border-white rounded-full flex items-center justify-center">
                <IoSettingsOutline color="white" className="h-11 w-11" />
              </div>
              <h1 className="text-white text-[25px] font-extrabold">
                Projects
              </h1>
              <p className="text-white text-lg font-medium text-center">
                Explore our variety of projects from Electronics to Software and
                more innovative projects
              </p>
            </div>
            <div className="h-[350px] flex flex-col items-center justify-between py-12 px-10 md:px-1 lg:px-10">
              <div className="h-20 w-20 border-2 border-white rounded-full flex items-center justify-center">
                <MdLiveTv color="white" className="h-11 w-11" />
              </div>
              <h1 className="text-white text-[25px] font-extrabold">
                Live Training
              </h1>
              <p className="text-white text-lg font-meduim text-center">
                Enroll for a course or live training to kickstart your career
                journey and gain valuable skills and knowledge
              </p>
            </div>
            <div className="h-[350px] flex flex-col items-center justify-between py-12 px-10 md:px-1 lg:px-10">
              <div className="h-20 w-20 border-2 border-white rounded-full flex items-center justify-center">
                <LuContact2 color="white" className="h-11 w-11" />
              </div>
              <h1 className="text-white text-[25px] font-extrabold">
                Contact us
              </h1>
              <p className="text-white text-lg font-meduim text-center">
                For inquiries or custom project requirements, please reach out
                to us promptly
              </p>
            </div>
          </div>
        </div>
        {screenWidth >= 1024 ? (
          <section
            className="bg-dark-bg w-full h-[600px] mt-20 flex items-center justify-center px-10 lg:px-10 xl:px-20"
            style={{
              clipPath: "polygon(0 0, 100% 0, 100% 85%, 0 100%)",
            }}
          >
            <div className="h-[80%] w-[50%]">
              <div className="h-auto w-[80%] border-white border-b">
                <h1
                  className="text-white text-[30px] font-medium pr-[8%] pb-7"
                  style={{
                    lineHeight: 1.3,
                  }}
                >
                  Let's match your current needs with the perfect project for
                  you!
                </h1>
              </div>
              <div className="flex mt-5 pl-6 gap-10">
                <h1
                  className={`text-[13px] cursor-pointer font-medium ${activeTab === 1 ? "text-white" : "text-[#FFFFFF80]"}`}
                  onClick={() => setActiveTab(1)}
                >
                  Final Year Project
                </h1>
                <h1
                  className={`text-[13px]  cursor-pointer font-medium ${activeTab === 2 ? "text-white" : "text-[#FFFFFF80]"}`}
                  onClick={() => setActiveTab(2)}
                >
                  Master’s Thesis
                </h1>
                <h1
                  className={`text-[13px]  cursor-pointer font-medium ${activeTab === 3 ? "text-white" : "text-[#FFFFFF80]"}`}
                  onClick={() => setActiveTab(3)}
                >
                  Live Training
                </h1>
              </div>
              <div>
                {activeTab === 1 && (
                  <div className="w-full h-[200px] pt-10 pr-[23%]">
                    <h1
                      className="text-white text-[17px] font-medium"
                      style={{
                        lineHeight: 1.2,
                      }}
                    >
                      We provide undergraduates with comprehensive final year
                      project assistance.
                    </h1>
                    <h1
                      className="text-[#FFFFFF80] text-[13px] font-medium mt-2"
                      style={{
                        lineHeight: 1.3,
                      }}
                    >
                      Need a project topic? Struggling with project development?
                      Explore our range of projects in software, electronics,
                      and mechanical domains, or feel free to contact us for
                      assistance
                    </h1>
                  </div>
                )}
                {activeTab === 2 && (
                  <div className="w-full h-[200px] pt-10 pr-[23%]">
                    <h1
                      className="text-white text-[17px] font-medium"
                      style={{
                        lineHeight: 1.2,
                      }}
                    >
                      We provide graduates with comprehensive master’s thesis
                      assistance.
                    </h1>
                    <h1
                      className="text-[#FFFFFF80] text-[13px] font-medium mt-2"
                      style={{
                        lineHeight: 1.3,
                      }}
                    >
                      Seeking inspiration for your master's thesis topic?
                      Encountering challenges in project development? Delve into
                      our diverse array of topics spanning software,
                      electronics, and mechanical engineering fields.
                      Alternatively, don't hesitate to reach out to us for
                      personalized guidance and support
                    </h1>
                  </div>
                )}
                {activeTab === 3 && (
                  <div className="w-full h-[200px] pt-10 pr-[23%]">
                    <h1
                      className="text-white text-[17px] font-medium"
                      style={{
                        lineHeight: 1.2,
                      }}
                    >
                      Get expert training in software development and IT to
                      advance your career.
                    </h1>
                    <h1
                      className="text-[#FFFFFF80] text-[13px] font-medium mt-2"
                      style={{
                        lineHeight: 1.3,
                      }}
                    >
                      Looking to advance in software development or IT? Discover
                      our extensive training programs and personalized support
                      for your professional growth and success.
                    </h1>
                  </div>
                )}
              </div>
            </div>
            <div className="h-[80%] w-[50%]">
              {activeTab === 1 && (
                <img
                  src={undergrad}
                  alt=""
                  className="h-[90%] w-full object-cover rounded-xl"
                />
              )}
              {activeTab === 2 && (
                <img
                  src={masters}
                  alt=""
                  className="h-[90%] w-full object-cover rounded-xl"
                />
              )}
              {activeTab === 3 && (
                <img
                  src={training}
                  alt=""
                  className="h-[90%] w-full object-cover rounded-xl"
                />
              )}
            </div>
          </section>
        ) : (
          <>
            <section
              className="bg-dark-bg w-full h-[750px] md:h-[950px] mt-20 px-5 pt-16"
              style={{
                clipPath: "polygon(0 0, 100% 0, 100% 90%, 0 100%)",
              }}
            >
              <div className="w-[90%] h-auto md:w-[50%] pb-10">
                <h1
                  className="text-white text-[25px] md:text-[30px] font-medium"
                  style={{
                    lineHeight: 1.3,
                  }}
                >
                  Let's match your current needs with the perfect project for
                  you!
                </h1>
              </div>
              <div className="h-auto w-[100%] border-white border-b"></div>
              <div className="flex mt-5 pl-6 gap-10">
                <h1
                  className={`text-[13px] md:text-[13px] cursor-pointer font-medium ${activeTab === 1 ? "text-white" : "text-[#FFFFFF80]"}`}
                  onClick={() => setActiveTab(1)}
                >
                  {screenWidth >= 500 ? (
                    <> Final Year Project</>
                  ) : (
                    <> Final Year</>
                  )}
                </h1>
                <h1
                  className={`text-[13px]  cursor-pointer font-medium ${activeTab === 2 ? "text-white" : "text-[#FFFFFF80]"}`}
                  onClick={() => setActiveTab(2)}
                >
                  {screenWidth >= 500 ? <> Master’s Thesis</> : <> Master’s</>}
                </h1>
                <h1
                  className={`text-[13px]  cursor-pointer font-medium ${activeTab === 3 ? "text-white" : "text-[#FFFFFF80]"}`}
                  onClick={() => setActiveTab(3)}
                >
                  Live Training
                </h1>
              </div>
              <div className="w-full h-[450px] md:h-[650px] pt-5">
                {activeTab === 1 && (
                  <div>
                    <div className="w-full h-[260px] rounded-md md:h-[500px]">
                      <img
                        src={undergrad}
                        alt=""
                        className="h-full w-full object-cover rounded-md"
                      />
                    </div>
                    <div className="pt-5 w-[100%] md:w-[50%]">
                      <h1
                        className="text-white text-[17px] font-medium"
                        style={{
                          lineHeight: 1.2,
                        }}
                      >
                        We provide undergraduates with comprehensive final year
                        project assistance.
                      </h1>
                      <h1
                        className="text-[#FFFFFF80] text-[13px] font-medium mt-4"
                        style={{
                          lineHeight: 1.3,
                        }}
                      >
                        Need a project topic? Struggling with project
                        development? Explore our range of projects in software,
                        electronics, and mechanical domains, or feel free to
                        contact us for assistance
                      </h1>
                    </div>
                  </div>
                )}
                {activeTab === 2 && (
                  <div>
                    <div className="w-full h-[260px] rounded-md md:h-[500px]">
                      <img
                        src={masters}
                        alt=""
                        className="h-full w-full object-cover rounded-md"
                      />
                    </div>
                    <div className="pt-5 w-[100%] md:w-[50%]">
                      <h1
                        className="text-white text-[17px] font-medium"
                        style={{
                          lineHeight: 1.2,
                        }}
                      >
                        We provide graduates with comprehensive master’s thesis
                        assistance.
                      </h1>
                      <h1
                        className="text-[#FFFFFF80] text-[13px] font-medium mt-2"
                        style={{
                          lineHeight: 1.3,
                        }}
                      >
                        Seeking inspiration for your master's thesis topic?
                        Encountering challenges in project development? Delve
                        into our diverse array of topics spanning software,
                        electronics, and mechanical engineering fields.
                        Alternatively, don't hesitate to reach out to us for
                        personalized guidance and support
                      </h1>
                    </div>
                  </div>
                )}
                {activeTab === 3 && (
                  <div>
                    <div className="w-full h-[260px] rounded-md md:h-[500px]">
                      <img
                        src={training}
                        alt=""
                        className="h-full w-full object-cover rounded-md"
                      />
                    </div>
                    <div className="pt-5 w-[100%] md:w-[50%]">
                      <h1
                        className="text-white text-[17px] font-medium"
                        style={{
                          lineHeight: 1.2,
                        }}
                      >
                        Get expert training in software development and IT to
                        advance your career.
                      </h1>
                      <h1
                        className="text-[#FFFFFF80] text-[13px] font-medium mt-2"
                        style={{
                          lineHeight: 1.3,
                        }}
                      >
                        Looking to advance in software development or IT?
                        Discover our extensive training programs and
                        personalized support for your professional growth and
                        success.
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </>
        )}
        <section className="w-full h-auto px-5 pt-[70px] pb-10 flex flex-col items-center">
          <div className="md:w-[70%] lg:w-[45%]">
            <h1 className="text-white text-3xl font-bold text-center md:text-[35px]">
              Projects Designed for Every Stage of Your Academic Journey
            </h1>
            <h1 className="text-white text-[13px] font-normal text-center pt-6 md:text-[16px]">
              Unlock your potential with our comprehensive project assistance
              programs and get the guidance, resources, and support you need to
              excel at every stage of your academic journey.
            </h1>
          </div>
          <div className="w-full flex flex-col pt-[50px] gap-6 md: items-center lg:flex-row lg:gap-3">
            <div className="bg-white-to-gray w-full px-6 py-10 md:py-16 h-[350px] md:h-[400px] lg:h-[370px] rounded-lg md:w-[60%] lg:w-full">
              <h1 className="text-lg uppercase font-extrabold mb-3 md:text-[20px]">
                Software Projects
              </h1>
              <div className="w-[70px] border-b-4 border-black"></div>
              <h1 className="text-base font-medium mt-5 md:text-[17px]">
                For undergraduate and graduate students majoring in disciplines
                such as Computer Science, Software Engineering, Information
                Technology, Computer Engineering, Data Science, and Information
                Science.
              </h1>
              <h1 className="text-base font-semibold underline cursor-pointer mt-5">
                Learn more
              </h1>
            </div>
            <div className="bg-white-to-gray border-4 border-[#39FF14] w-full px-6 py-10 md:py-16 h-[350px] md:h-[400px] lg:h-[370px] rounded-lg md:w-[60%] lg:w-full">
              <h1 className="text-lg uppercase font-extrabold mb-3 md:text-[20px]">
                Electronics Projects
              </h1>
              <div className="w-[70px] border-b-4 border-black"></div>
              <h1 className="text-base font-medium mt-5 md:text-[17px]">
                For undergraduate and graduate students majoring in disciplines
                such as electrical engineering, electronic engineering, computer
                engineering, mechatronics engineering, robotics engineering,
                telecommunications engineering and embedded systems engineering.
              </h1>
              <h1 className="text-base font-semibold underline cursor-pointer mt-5">
                Learn more
              </h1>
            </div>
            <div className="bg-white-to-gray w-full px-6 py-10 md:px-10 md:py-16 h-[350px] md:h-[400px] lg:h-[370px] rounded-lg md:w-[60%] lg:w-full">
              <h1 className="text-lg uppercase font-extrabold mb-3 md:text-[20px]">
                Mechanical Projects
              </h1>
              <div className="w-[70px] border-b-4 border-black"></div>
              <h1 className="text-base font-medium mt-5 md:text-[17px]">
                For undergraduate and graduate students majoring in disciplines
                such as Mechanical Engineering, Industrial Engineering, and
                related fields.
              </h1>
              <h1 className="text-base font-semibold underline cursor-pointer mt-5">
                Learn more
              </h1>
            </div>
          </div>
        </section>
        <section className="w-full h-[750px] flex items-center justify-center mt-[30px] lg:h-[500px] lg:mt-[70px]">
          <div className="bg-[#39FF14] w-[95%] h-[90%] rounded-md lg:flex lg:">
            <div
              className="w-[100%] h-[200px] flex  flex-col justify-center px-8 md:px-28 lg:w-[50%] lg:h-full lg:justify-start lg:px-16 lg:pt-16"
              style={{
                borderTopRightRadius: "6px",
                borderTopLeftRadius: "6px",
              }}
            >
              <h1 className="text-white text-2xl font-semibold md:text-3xl lg:text-4xl">
                Sign up for our newsletter today
              </h1>
              <h1 className="text-white text-sm font-medium md:text-base md:mt-2">
                By subscribing, you'll be the first to know about exciting
                updates on our latest projects, trainings, special offers and
                promotions
              </h1>
            </div>
            <div
              className="w-[100%] h-[470px] px-8 pt-5  md:px-28 lg:w-[50%] lg:h-full lg:px-10 lg:pt-11"
              style={{
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
              }}
            >
              <form action="">
                <h1 className="text-white uppercase text-[13px] font-semibold mb-1">
                  First name
                </h1>
                <input
                  type="text"
                  className="w-full h-10 rounded-[4px] outline-none pl-4 mb-4"
                  placeholder="First name"
                />
                <h1 className="text-white uppercase text-[13px] font-semibold mb-1">
                  Last name
                </h1>
                <input
                  type="text"
                  className="w-full h-10 rounded-[4px] outline-none pl-4 mb-4"
                  placeholder="Last name"
                />
                <h1 className="text-white uppercase text-[13px] font-semibold mb-1">
                  Email
                </h1>
                <input
                  type="text"
                  className="w-full h-10 rounded-[4px] outline-none pl-4 mb-7"
                  placeholder="Email"
                />

                <div className="flex items-center">
                  <input type="checkbox" id="tc" className="large-checkbox" />
                  <h1 className="text-white text-[12px] font-semibold ml-2 lg:text-[14px] lg:font-medium">
                    Yes, I’d like to receive emails from Servus Technologies™
                  </h1>
                </div>
                <button
                  type="submit"
                  className="w-full h-14 bg-black text-white mt-10 uppercase lg:w-[200px]"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </section>
        <section className="bg-dark-bg h-20  pt-8 w-full md:h-32 md:pt-10">
          <div className="border-white border-b-2 w-[full] mx-4 mt-10"></div>
        </section>
        <section className="bg-dark-bg w-full h-[700px] lg:h-[300px] pt-16 lg:flex">
          <div className="w-full h-[200px] pl-3 pr-5 md:h-[150px] md:pr-[55%]  lg:w-[35%] lg:pr-[60px] lg:h-full lg:pl-4">
            <h1
              className="text-white text-2xl font-semibold"
              style={{
                lineHeight: 1.2,
              }}
            >
              About Servus Technologies
            </h1>
            <p
              className="text-[#7d8585] mt-4"
              style={{
                lineHeight: 1.2,
              }}
            >
              Servus Technologies Limited is a global educational solutions
              company that specializes in electronics, software, and mechanical
              projects. We offer a range of services including final year
              project consultancy, master's thesis support, and training
              courses.
            </p>
          </div>
          <div className="flex md:w-[65%] lg:w-[32%]">
            <div className="w-[50%] h-[250px] px-3">
              <h1 className="text-white text-[15px] font-semibold mb-4 lg:text-sm">
                Products
              </h1>
              <div className="flex flex-col gap-2 text-[#7d8585] text-[15px] lg:text-[13px]">
                <h1 className="cursor-pointer">Electronics</h1>
                <h1 className="cursor-pointer">Sotfware</h1>
                <h1 className="cursor-pointer">Mechanical</h1>
                <h1 className="cursor-pointer">Live training</h1>
              </div>
            </div>
            <div className="w-[50%] h-[250px] px-3">
              <h1 className="text-white text-[15px] font-semibold mb-4 lg:text-sm">
                Collaborate
              </h1>
              <div className="flex flex-col gap-2 text-[#7d8585] text-[15px] lg:text-[13px]">
                <h1 className="cursor-pointer">Student Ambassador</h1>
                <h1 className="cursor-pointer">Partners</h1>
                <h1 className="cursor-pointer">Community</h1>
                <h1 className="cursor-pointer">Careers</h1>
                <h1 className="cursor-pointer">Contact us</h1>
              </div>
            </div>
          </div>
          <div className="flex md:w-[65%]  lg:w-[32%]">
            <div className="w-[50%] h-[250px] px-3">
              <h1 className="text-white text-[15px] font-semibold mb-4 lg:text-sm">
                Content
              </h1>
              <div className="flex flex-col gap-2 text-[#7d8585] text-[15px] lg:text-[13px]">
                <h1 className="cursor-pointer">Undergraduate</h1>
                <h1 className="cursor-pointer">Master's</h1>
                <h1 className="cursor-pointer">Live training</h1>
                <h1 className="cursor-pointer">Custom</h1>
              </div>
            </div>
            <div className="w-[50%] h-[250px] px-3">
              <h1 className="text-white text-[15px] font-semibold mb-4 lg:text-sm">
                About Us
              </h1>
              <div className="flex flex-col gap-2 text-[#7d8585] text-[15px] lg:text-[13px]">
                <h1 className="cursor-pointer">Our Mission</h1>
                <h1 className="cursor-pointer">Team</h1>
                <h1 className="cursor-pointer">FAQ</h1>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Home;
