import { Input, FloatButton, ConfigProvider } from "antd";
import { IoMdAdd } from "react-icons/io";
import { useState } from "react";
import CourseModal from "../../components/coursesModal";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Course = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAdd = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <div className="w-full" style={{ height: "calc(100vh - 150px)" }}></div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#1B49C1",
          },
        }}
      >
        <FloatButton
          className="h-[60px] w-[60px] right-[20px] bottom-[10px] lg:right-[40px] lg:bottom-[10px]"
          type="primary"
          icon={<IoMdAdd size={20} />}
          onClick={handleAdd}
        />
      </ConfigProvider>
      <CourseModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default Course;
