import { IoIosArrowDown } from "react-icons/io";
import { ConfigProvider, Tabs } from "antd";
import Course from "./courses";

const Dashboard = () => {
  return (
    <div>
      <DashboardNavbar />
      <div className="px-5 lg:px-10">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor:"#39FF14",
                itemHoverColor:"#FFFFFF",
                itemSelectedColor:"#fff",
                itemColor:"gray"
              },
            },
          }}
        >
          <Tabs style={{ fontWeight: "500" }}>
            <Tabs.TabPane tab="Courses" key="tab1">
              <div>
                <Course/>  
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Order Pending" key="tab2">
              <div></div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Order Processing" key="tab3">
              <div></div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="In Transist" key="tab4">
              <div></div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Completed" key="tab5">
              <div></div>
            </Tabs.TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default Dashboard;

const DashboardNavbar = () => {
  return (
    <div className="bg-dark-bg h-20 w-full px-5 flex justify-between items-center lg:px-10">
      <h1 className="text-white text-2xl font-semibold">Admin Panel</h1>
      <div className="flex gap-2 items-center">
        <div className="bg-red-600 h-12 w-12 rounded-full">
          <img
            src="https://img.freepik.com/free-photo/front-view-man-with-graphic-eye-makeup_23-2150723144.jpg?t=st=1721906112~exp=1721909712~hmac=3cb5a0c1d78d5ac65fdd27bdfb06921c116d4cf907e0a66fa62b261eed0bc4a3&w=826"
            alt=""
            className="h-full w-full object-cover rounded-full"
          />
        </div>
        <IoIosArrowDown className="text-white cursor-pointer" />
      </div>
    </div>
  );
};
