const CourseTile = ({ course }) => {
  const formattedPrice = Number(course.price).toLocaleString();
  return (
    <div className="bg-dark-bg shadow-2xl h-[570px] relative">
      <div className="w-full h-[230px]">
        <img
          src={course.image}
          alt=""
          className="h-full w-full object-cover object-top"
        />
      </div>
      <div className="bg-[#39FF14] w-full h-[70px] md:px-6 flex items-center px-3">
        <div className="flex gap-2 items-center justify-center">
          <h1 className="text-white font-extrabold text-sm uppercase">From</h1>
          <h2 className="text-white font-extrabold text-xl">{`₦${formattedPrice}`}</h2>
        </div>
      </div>
      <div className="w-full px-4 pt-2 h-[250px] md:px-8 md:pt-5 relative">
        <h1 className="text-white text-[19px] font-extrabold cursor-pointer">
          {course.title}
        </h1>
        <div className="h-[100px] break-words overflow-hidden mt-5">
          <p className="text-white text-[15px] font-medium line-clamp tracking-widest">
            {course.desc}
          </p>
        </div>
        <h1 className="text-[#39FF14] text-[13px] font-[900] uppercase underline absolute bottom-0 mb-5 tracking-wider cursor-pointer">
          learn more
        </h1>
      </div>
    </div>
  );
};

export default CourseTile;
