import { useState, useEffect } from "react";
import { Modal, ConfigProvider, Button, Input, Form, Select } from "antd";
import { db, storage } from "../firebase";
import { addDoc, collection, updateDoc, doc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";
import Dropzone from "./dropzpne";
const { TextArea } = Input;

const CourseModal = ({ isModalOpen, setIsModalOpen }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedImage, setSelectedImage] = useState([]);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleUpload = async () => {
    if (selectedImage.length === 0) {
      toast.error("No selected image");
    } else {
      try {
        setLoading(true);
        const docRef = await addDoc(collection(db, "courses"), {
          title,
          price,
          category,
          desc,
          Instructor:String("ServusTech")
        });
        await Promise.all(
          selectedImage.map((image) => {
            const imageRef = ref(storage, `courses/${image.path}`);
            uploadBytes(imageRef, image, "data_url").then(async () => {
              const downloadURL = await getDownloadURL(imageRef);
              await updateDoc(doc(db, "courses", docRef.id), {
                image: downloadURL,
                courseID: docRef.id,
              });
            });
          })
        );
        setLoading(false);
        setSelectedImage([]);
        toast.success("Course added successfully");
        form.resetFields();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleFontSize: 20,
          },
        },
      }}
    >
      {screenWidth >= 640 ? (
        <Modal
          title="Add Course Modal"
          open={isModalOpen}
          onCancel={handleCancel}
          centered
          width={460}
          footer={[
            <Button
              key={"continue"}
              loading={loading}
              disabled={loading}
              type="primary"
              className="bg-[#1B49C1] text-white h-11 rounded-[12px] text-base font-semibold w-full"
              onClick={handleUpload}
            >
              Create
            </Button>,
          ]}
        >
          <div className="h-[270px] overflow-auto">
            <Form layout="vertical" className="w-full px-3" form={form}>
              <div className="my-3">
                <Dropzone
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
              <Form.Item
                label="Course Title"
                name="name"
                style={{ marginBottom: "10px" }}
                labelCol
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid name",
                  },
                ]}
              >
                <Input
                  className="h-10 md:h-9 w-full"
                  placeholder="Course Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Course Price"
                name="price"
                style={{ marginBottom: "10px" }}
                labelCol
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid price",
                  },
                ]}
              >
                <Input
                  className="h-10 md:h-9 w-full"
                  placeholder="Course Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Course Category"
                name="category"
                style={{ marginBottom: "20px" }}
                labelCol
                rules={[
                  {
                    required: true,
                    message: "Please select a valid category",
                  },
                ]}
              >
                <Select
                  className="h-10 md:h-9"
                  onChange={(value) => {
                    setCategory(value);
                  }}
                  style={{ fontSize: "90px" }}
                >
                  <Select.Option value="Electronic Projects">
                    <span style={{ fontWeight: "500" }}>
                      Electronic Projects
                    </span>
                  </Select.Option>
                  <Select.Option value="Software Projects">
                    <span style={{ fontWeight: "500" }}>Software Projects</span>
                  </Select.Option>
                  <Select.Option value="Mechanical Projects">
                    <span style={{ fontWeight: "500" }}>
                      Mechanical Projects
                    </span>
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Course Description"
                style={{ marginBottom: "20px" }}
                name="desc"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid description",
                  },
                ]}
              >
                <TextArea
                  allowClear
                  showCount
                  maxLength={950}
                  style={{ fontWeight: "500" }}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      ) : (
        <Modal
          style={{
            marginBottom: "100px",
          }}
          title="Add Course Modal"
          open={isModalOpen}
          onCancel={handleCancel}
          wrapClassName="flex justify-end items-end"
          width="90%"
          footer={[
            <Button
              key={"continue"}
              loading={loading}
              disabled={loading}
              type="primary"
              className="bg-[#1B49C1] text-white h-11 rounded-[12px] text-base font-semibold w-full"
              onClick={handleUpload}
            >
              Confirm
            </Button>,
          ]}
        >
          <div className="h-[270px] overflow-auto">
            <Form layout="vertical" className="w-full px-3" form={form}>
              <Form.Item
                label="Course Title"
                name="name"
                style={{ marginBottom: "10px" }}
                labelCol
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid name",
                  },
                ]}
              >
                <Input
                  className="h-10 md:h-9 w-full"
                  placeholder="Course Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Course Price"
                name="price"
                style={{ marginBottom: "10px" }}
                labelCol
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid price",
                  },
                ]}
              >
                <Input
                  className="h-10 md:h-9 w-full"
                  placeholder="Course Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Course Category"
                name="category"
                style={{ marginBottom: "20px" }}
                labelCol
                rules={[
                  {
                    required: true,
                    message: "Please select a valid category",
                  },
                ]}
              >
                <Select
                  className="h-10 md:h-9"
                  onChange={(value) => {
                    setCategory(value);
                  }}
                  style={{ fontSize: "90px" }}
                >
                  <Select.Option value="Electronic Projects">
                    <span style={{ fontWeight: "500" }}>
                      Electronic Projects
                    </span>
                  </Select.Option>
                  <Select.Option value="Software Projects">
                    <span style={{ fontWeight: "500" }}>Software Projects</span>
                  </Select.Option>
                  <Select.Option value="Mechanical Projects">
                    <span style={{ fontWeight: "500" }}>
                      Mechanical Projects
                    </span>
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Course Description"
                style={{ marginBottom: "20px" }}
                name="desc"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid description",
                  },
                ]}
              >
                <TextArea
                  allowClear
                  showCount
                  maxLength={950}
                  style={{ fontWeight: "500" }}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      )}
    </ConfigProvider>
  );
};

export default CourseModal;
