import { ConfigProvider, Select } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import React from "react";
const { Option } = Select;

const SortingDropdown = ({ sortOption, handleSortChange }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: "0px",
          fontSize: "16px",
          fontSizeIcon: "16px",
          colorPrimaryHover: "#d9d9d9",
        },
        components: {
          Select: {
            optionFontSize: "16px",
            optionPadding: "5px 12px",
          },
        },
      }}
    >
      <Select
        defaultValue="priceLowHigh"
        value={sortOption}
        className="w-[90%] md:w-[399px] h-[55px]"
        onChange={handleSortChange}
        suffixIcon={<IoIosArrowDown className="mt-[-10px]" />}
      >
        {/* <Option value="popularity">Sort by Popularity</Option>
        <Option value="rating">Sort by Average Rating</Option> */}
        <Option value="priceLowHigh">Sort by price: Low to High</Option>
        <Option value="priceHighLow">Sort by price: High to Low</Option>
      </Select>
    </ConfigProvider>
  );
};

export default SortingDropdown;