import { NavLink, useNavigate } from "react-router-dom";
import { RiMenu2Line, RiCloseLine } from "react-icons/ri";
import { useState } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const Links = [
    { name: "Electronic Projects", link: "/electronics" },
    { name: "Software Projects", link: "/electronics" },
    { name: "Mechanical Projects", link: "/electronics" },
    { name: "Live training", link: "/electronics" },
  ];
  const [open, setOpen] = useState(false);
  return (
    <div className="z-10 w-full fixed top-0 left-0 ">
      <div className="lg:flex bg-dark-bg lg: items-center justify-between py-8 md:px-14 px-9">
        <div className="cursor-pointer" onClick={() => navigate("/")}>
          <h1 className="logo">Servustech®</h1>
        </div>
        <div
          className="text-3xl absolute right-8 top-6 cursor-pointer lg:hidden"
          onClick={() => setOpen(!open)}
        >
          {open ? <RiCloseLine color="white" /> : <RiMenu2Line color="white" />}
        </div>
        <ul
          className={`lg:flex lg:items-center lg:pb-0 absolute lg:static bg-dark-bg lg:z-auto z-[-1] left-0 w-full lg:w-auto md:pl-14 lg:pl-0 pl-9 lg:pt-0 pt-5 transition-all duration-200 ease-in ${open ? "top-20" : "top-[-490px]"}`}
        >
          {Links.map((link) => (
            <li
              key={link.name}
              className="text-color-white contrast-200 lg:ml-8 text-sm lg:my-0 my-7"
            >
              <NavLink to={link.link} onClick={() => setOpen(false)}>
                {link.name}
              </NavLink>
            </li>
          ))}
          <button className="bg-[#39FF14] text-white w-28 h-10 text-sm font-extrabold rounded mb-5 lg:mb-0 lg:ml-6">
            Contact Us
          </button>
        </ul>
      </div>
    </div>
  );
};
export default Navbar;
