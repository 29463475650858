import { ConfigProvider, Select } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
  updateDoc,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import CourseTile from "../components/courseTile";
import SortingDropdown from "../components/sortingDropDown";

const ElectronicCourses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState("priceLowHigh");

  useEffect(() => {
    const getCourses = async () => {
      const coursesRef = collection(db, "courses");
      const dataQuery = query(
        coursesRef,
        where("category", "==", "Electronic Projects")
      );
      const querySnapshots = await getDocs(dataQuery);
      let data = [];
      querySnapshots.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      console.log(data);
      setCourses(data);
      setLoading(false);
    };

    getCourses();
  }, []);

  const handleSortChange = (value) => {
    setSortOption(value);
    let sortedCourses = [...courses];
    if (value === "priceLowHigh") {
      sortedCourses.sort((a, b) => a.price - b.price);
    } else if (value === "priceHighLow") {
      sortedCourses.sort((a, b) => b.price - a.price);
    }
    setCourses(sortedCourses);
  };

  return (
    <div className="bg-[#F8F8FF] w-full h-auto mt-[80px] md:mt-[105px]">
      <div className="bg-dark-bg w-full pt-[150px] px-9 md:px-14 h-[450px] md:h-[550px] md:pt-[180px]">
        <div className="w-[100%] md:w-[420px] lg:w-[470px]">
          <h1 className="text-white text-[60px] font-semibold leading-[1] md:text-[90px] lg:text-[110px]">
            Electronics Projects
          </h1>
        </div>
        <div className="w-[100%] mt-5 md:w-[600px] lg:w-[800px] md:mt-7 lg:mt-8">
          <h1 className="text-white text-[20px] font-light md:text-[22px] lg:text-[24px]">
            Unlock your potential with our comprehensive electronics project
            courses, perfect for enthusiasts and professionals.
          </h1>
        </div>
      </div>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-center justify-center md:justify-end pt-5 pb-5 md:pb-0 md:px-10 md:py-10">
          <SortingDropdown
            sortOption={sortOption}
            handleSortChange={handleSortChange}
          />
        </div>
        {loading ? (
          <div className="w-full h-screen flex justify-center items-center">
            <h1>loading</h1>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-5 md:p-10">
            {courses.map((course) => (
              <CourseTile key={course.id} course={course} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ElectronicCourses;

// const SortingDropdown = () => {
//   const [sortOption, setSortOption] = useState("popularity");

//   const handleChange = (value) => {
//     setSortOption(value);
//   };

//   return (
//     <ConfigProvider
//       theme={{
//         token: {
//           borderRadius: "0px",
//           fontSize: "16px",
//           fontSizeIcon: "16px",
//           colorPrimaryHover: "#d9d9d9",
//         },
//         components: {
//           Select: {
//             optionFontSize: "16px",
//             optionPadding: "5px 12px",
//           },
//         },
//       }}
//     >
//       <Select
//         defaultValue="popularity"
//         value={sortOption}
//         className="w-[90%] md:w-[399px] h-[55px]"
//         onChange={handleChange}
//         suffixIcon={<IoIosArrowDown className="mt-[-10px]" />}
//       >
//         <Option value="popularity">Sort by Popularity</Option>
//         <Option value="rating">Sort by Average Rating</Option>
//         <Option value="priceLowHigh">Sort by price: Low to High</Option>
//         <Option value="priceHighLow">Sort by price: High to Low</Option>
//       </Select>
//     </ConfigProvider>
//   );
// };
