import React from "react";
import { Form, Input, Button, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { MdOutlineMailOutline, MdOutlineLock } from "react-icons/md";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ error: false, msg: "" });

  const handleSubmit = async () => {
    setLoading(true)
    try {
      if (!email || !password) {
        setMessage({ error: true, msg: "All fields are required" });
        setLoading(false);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        setLoading(false);
        setMessage("");
        navigate("/admin-dashboard");
      }
    } catch (error) {
      setMessage({ error: true, msg: error.message });
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="bg-dark-bg flex items-center justify-center min-h-screen px-4">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold text-white">Admin Login</h1>
        </div>
        {message?.msg && (
          <Alert
            message=""
            description={message?.msg}
            type="error"
            showIcon
            className="my-3"
          />
        )}

        <Form onFinish={handleSubmit} onSubmit={(e) => e.preventDefault()}>
          <Form.Item>
            <Input
              className="w-full h-10 text-gray-900 rounded-lg focus:outline-none text-[13px]"
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              prefix={<MdOutlineMailOutline className="" />}
            />
          </Form.Item>
          <Form.Item>
            <Input
              className="w-full h-10 text-gray-900 rounded-lg focus:outline-none text-[13px]"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              prefix={<MdOutlineLock />}
            />
          </Form.Item>
          <Form.Item>
            {/* <button
              
              onClick={() => navigate("/dashboard")}
            >
              Login
            </button> */}
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-full bg-blue-600 text-white font-semibold h-11 rounded-lg hover:bg-blue-700 transition duration-200"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
