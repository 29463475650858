import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar";
import Home from "./pages/home";
import Login from "./pages/admin/login";
import Dashboard from "./pages/admin/dashboard";
import ElectronicCourses from "./pages/electronics";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/dashboard" element={<ProtectedRoute>
        <Dashboard/>
      </ProtectedRoute>} />
      <Route path="/admin-dashboard" element={<ProtectedRoute>
        <AdminDashboard/>
      </ProtectedRoute>} />
      <Route exact path="/application/:id" element={<ProtectedRoute>
        <ApplicationForm/>
      </ProtectedRoute>} /> */}
        <Route path="/admin-login" element={<Login />} />
        <Route path="/admin-dashboard" element={<Dashboard />} />
        <Route
          element={
            <>
              <Navbar />
              <Outlet />
            </>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/electronics" element={<ElectronicCourses/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
